import React from "react"
import { Modal } from "@redq/reuse-modal"
import { graphql, useStaticQuery } from "gatsby"

import ChangeYourLife from "../containers/ChangeYourLife"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import Treatments from "../containers/Treatments"
import TreatmentsSelect from "../containers/TreatmentsSelect"
import TreatmentsStories from "../containers/TreatmentsStories"
import VideoSection from "../containers/VideoSection"
import { ContentWrapper } from "../containers/elegant.style"

const Skin = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTreatments(
        filter: { treatmentCategory: { eq: "skin" } }
        sort: { fields: updatedAt, order: DESC }
      ) {
        edges {
          node {
            treatmentTitle
            treatmentDescription {
              treatmentDescription
            }
            treatmentThumbnailImage {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            slug
            treatmentCategory
          }
        }
      }
      featureImage: file(
        relativePath: { eq: "image/elegant/skin-preview-video.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      treatmentsStoriesImage: file(
        relativePath: { eq: "image/elegant/treatmentsSkin/skin.png" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      elegantJson {
        treatmentsSkinStories {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }
        treatmentsSkinIntro {
          title
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const url = location.href ? location.href : ""
  return (
    <>
      <SEO
        url={url}
        title="Skin Treatments in Bournemouth & Poole"
        shortDesc="Elegant Beauty Clinic"
        description="At Elegant Beauty Clinic we offer a wide range of award winning skin treatments to help you achieve skin perfection and treat any skin conditions you may have."
      />
      <Layout>
        <Modal />
        <ContentWrapper>
          <PageTitle
            title="Luxury Skin Services"
            subtitle="Elegant Aesthetic"
          />
          <TreatmentsSelect
            treatmentsData={data.allContentfulTreatments}
            category="skin"
          />
          <VideoSection
            hidePlayButton={true}
            placeholder={data.featureImage}
            videoUrl="https://www.youtube.com/embed/yd1LemPyV2Y?&theme=dark&autohide=2&autoplay=1&modestbranding=1&showinfo=0&showsearch=0&rel=0&iv_load_policy=3&cc_load_policy=1"
          />
          <Treatments
            introContent={data.elegantJson.treatmentsSkinIntro}
            treatmentsData={data.allContentfulTreatments}
          />
          <TreatmentsStories
            treatmentsContent={data.elegantJson.treatmentsSkinStories}
            treatmentsStoriesImage={data.treatmentsStoriesImage}
          />
          <ChangeYourLife
            title="Ready to begin your Elegant Skin journey?"
            text="We’re here and ready to help you make the step towards a more Elegant you. Explore our treatments, visit us for a consultation and book your appointment today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default Skin
